import React from 'react'
import Header from "./layout/header";
import Footer from "./layout/footer";

function TermsService() {
    return (
    <>
        <Header/>
            <main>
                <div class="container">
                    <section className="section terms-wrapper">
                    <h1>Terms of Service</h1>
                    <p><strong>Effective Date:</strong> Oct. 1, 2024</p>
                    <p>Welcome to HT Info! By using our app, you agree to the following terms of service ("Terms"). Please read them carefully before using our app on iOS or Android.</p>
                    
                    <h2>1. Acceptance of Terms</h2>
                    <p>By downloading, accessing, or using the HT Info ("App"), you agree to be bound by these Terms. If you do not agree, do not use the App.</p>

                    <h2>2. Changes to Terms</h2>
                    <p>We reserve the right to modify these Terms at any time. Any changes will be posted within the App, and your continued use after such changes are made constitutes acceptance of the new Terms.</p>

                    <h2>3. Use of the App</h2>
                    <p>You must be at least 13 years old to use this App. You agree to use the App in compliance with all applicable laws, rules, and regulations. You are responsible for any activity that occurs under your account.</p>

                    <h2>4. Privacy</h2>
                    <p>We take your privacy seriously. Please review our <a href="/app/privacy-policy">Privacy Policy</a> to understand how we collect, use, and protect your information.</p>
                    
                    <h2>5. Account Security</h2>
                    <p>You are responsible for maintaining the confidentiality of your login credentials. Facilities App is not responsible for any unauthorized access to your account.</p>
                    
                    <h2>6. App Content</h2>
                    <p>All content provided on the App, including text, images, and other materials, is the property of Facilities App or its licensors. You agree not to reproduce, modify, or distribute the content without permission.</p>

                    <h2>7. Third-Party Services</h2>
                    <p>The App may include links to third-party services or websites. Facilities App is not responsible for any third-party services or content.</p>

                    <h2>8. Prohibited Conduct</h2>
                    <ul>
                        <li>Use the App for any illegal or unauthorized purposes.</li>
                        <li>Interfere with or disrupt the operation of the App.</li>
                        <li>Upload any harmful code, virus, or malware.</li>
                        <li>Attempt to gain unauthorized access to other user accounts or the App’s systems.</li>
                    </ul>

                    <h2>9. Termination</h2>
                    <p>We reserve the right to terminate or suspend your access to the App at any time, without notice, for conduct that violates these Terms or is harmful to other users or the App.</p>

                    <h2>10. Disclaimer of Warranties</h2>
                    <p>The App is provided "as is" without warranties of any kind. We do not guarantee that the App will be error-free or available at all times.</p>

                    <h2>11. Limitation of Liability Facilities App</h2>
                    <p>Limitation of Liability Facilities App and its affiliates are not liable for any indirect, incidental, or consequential damages arising from your use of the App.</p>

                    <h2>12. Governing Law</h2>
                    <p>These Terms are governed by and construed in accordance with the laws of India. Any disputes will be resolved in the courts of Thiruvananthapuram, Kerala, India.</p>

                    <h2>13. Contact Us</h2>
                    <p>If you have any questions about these Terms, please contact us at <a href="mailto:info@xilligence.com">info@xilligence.com</a></p>

                    <p>By using the HT Info, you acknowledge that you have read and agree to these Terms of Service.</p>
                </section>
                </div>
            </main>
        <Footer/>
    </>

    )
}

export default TermsService;