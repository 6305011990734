import React from "react";
import Icon07 from './images/customer-service.svg'
function ServiceAIInfo() {
  return (
    <section className="image-description-section section">
        
    <div className="image-description-left">
        <img src={Icon07} alt="Description Image" className="image_description"/>
    </div>
    <div className="image-description-right description">
        {/* <div className="title color-bright-2">AI-Powered Customer Support</div> */}
        <h2>Revolutionize Your Customer Support  <span className="gradient-one"> with AI</span></h2>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Chatbots and Virtual Assistants:</span> Deploy AI-driven chatbots for instant, round-the-clock
        customer support.
        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold"> Sentiment Analysis:</span> Use AI to analyze customer interactions and gauge sentiment,
allowing for proactive service improvements.

        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Personalized Support:</span> AI algorithms tailor support responses based on customer profiles
and past interactions, enhancing satisfaction.

        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Automated Ticketing:</span> Streamline your ticketing system with AI that categorizes and
prioritizes issues for faster resolution.
        </p>
    </div>
</section>  
  )
}
export default ServiceAIInfo;