import React from "react";
import Icon06 from './images/database.svg'
function ServiceDevOpsInfo() {
  return (
    <section className="image-description-section section">
        
    <div className="image-description-left">
        <img src={Icon06} alt="Description Image" className="image_description"/>
    </div>
    <div className="image-description-right description">
        {/* <div className="title color-bright-2">Managed DevOps Services</div> */}
        <h2>Enhance Your Development and Operations  <span className="gradient-one">with AI</span></h2>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Continuous Integration and Delivery (CI/CD)**:</span> AI-enhanced pipelines for faster, more
reliable software delivery.

        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Automated Testing**:</span> Leverage AI to automate testing processes, ensuring higher
quality software releases.


        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Performance Monitoring**:</span> AI-driven tools monitor application performance, identifying
bottlenecks and optimizing user experience.

        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">DevSecOps Integration**:</span> Seamlessly integrate security into your DevOps practices with
        AI-powered threat detection and response.
        </p>
    </div>
</section>  
  )
}
export default ServiceDevOpsInfo;