import React, { useState } from "react";
import '../css/style.css'
import '../css/responsive.css'
// import '../css/owl.carousel.min.css'
// import '../css/owl.theme.default.min.css'
import '../css/line-awesome.min.css'
import Logo from '../images/hti.png'
function Header() {
    const [showMenu,setShowMenu] = useState(false)
    const [toggleIconMenu,setToggleIconMenu] = useState(false)
    const toggleMenu = () =>{
        setShowMenu(!showMenu)
    } 
    const toggleIcon =() =>{
        setToggleIconMenu(!toggleIconMenu)
    }
  return (
    <>
    <div className="bouncing-blobs-container">
    <div className="bouncing-blobs-glass"></div>
    <div className="bouncing-blobs">
      <div className="bouncing-blob bouncing-blob--blue"></div>
      <div className="bouncing-blob bouncing-blob--blue"></div>
      <div className="bouncing-blob bouncing-blob--blue"></div>
      <div className="bouncing-blob bouncing-blob--violet"></div>
      <div className="bouncing-blob bouncing-blob--purple"></div>
      <div className="bouncing-blob bouncing-blob--purple"></div>
      <div className="bouncing-blob bouncing-blob--pink"></div>
    </div>
</div>

<div className="custom-cursor" id="js-cursor">
    <div className="cursor__small abs-center" id="js-cursor__small"></div>
    <div className="cursor__big abs-center" id="js-cursor__big"> 
    </div>
 </div>
    <header className="header">
    <div className="container">        
        <div className="logo">
            <a href="/"><img src={Logo} width={60} height={48} alt="logo"/></a>
        </div>
        <nav className="navbar">
            <ul className={`nav-menu ${toggleIconMenu ? 'active' : ''}`}>
                <div className="logo mobile-logo">
                    <a href="/"><h2>HITECH</h2></a>
                </div>
                <div className="menu">
                <li className="menu-item">
                    <a href="/">Home</a>
                </li>
                <li className="menu-item">
                    <a href="/about">About</a>
                </li>                
                <li className="menu-item has-submenu">
                    <span onClick={()=>toggleMenu()} className={showMenu ? 'uparrow' : ''}>Services</span>
                    <ul className={`submenu ${showMenu ? 'show' : ''}`}>
                        <li><a href="/AI-Driven-IT-Support">AI-Driven IT Support</a></li>
                        <li><a href="/Cybersecurity-Services">Cybersecurity Services</a></li>
                        <li><a href="/Data-Analytics&Business-Intelligence">Data Analytics & Business Intelligence</a></li>
                        <li><a href="/Cloud-Management-Services">Cloud Management Services</a></li>
                        <li><a href="/Digital-Transformation-Solutions">Digital Transformation Solutions</a></li>
                        <li><a href="/Managed-DevOps-Services">Managed DevOps Services</a></li>
                        <li><a href="/AI-Powered-Customer-Support">AI-Powered Customer Support</a></li>
                        <li><a href="/software-development">Software Development</a></li>
                    </ul>
                </li>
                {/* <li className="menu-item has-submenu">
                    <a href="#portfolio">Portfolio</a>
                    <ul className="submenu">
                        <li><a href="#team">Team</a></li>
                        <li><a href="#company">Company</a></li>
                        <li><a href="#team">Team</a></li>
                        <li><a href="#company">Company</a></li>
                    </ul>
                </li> */}
                 <li className="menu-item">
                    <a href="/client-partners">Client & Partners</a>
                </li>
                <li className="menu-item">
                    <a href="/contact">Contact</a>
                </li>
                </div>
                <div className="mobile-social">
                    <div className="social">
                        <div className="effect varrius">
                            <div className="buttons">
                              <a href="https://www.facebook.com/" className="fb curserhover" title="Join us on Facebook"><i className="la la-facebook icon" aria-hidden="true"></i></a>
                              <a href="https://twitter.com/" className="tw curserhover" title="Join us on Twitter"><i className="la la-twitter icon" aria-hidden="true"></i></a>
                              <a href="https://www.youtube.com/channel/" className="youtube curserhover" title="Join us on youtube"><i className="la la-play icon" aria-hidden="true"></i></a>
                              <a href="https://www.instagram.com/" className="insta curserhover" title="Join us on Instagram"><i className="la la-instagram icon" aria-hidden="true"></i></a>
                              <a href="https://www.linkedin.com/company/world--league" className="linked curserhover" title="Join us on Instagram"><i className="la la-linkedin icon" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </ul>
        </nav>
        <div className={`menu-toggle ${toggleIconMenu ? 'active' : ''}`} onClick={() => toggleIcon()}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</header>
</>
  )
}
export default Header;