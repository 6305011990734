import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import ServiceAIBanner from "./service-client-partners";
import ServiceAIInfo from "./service-client-partners-info";
function ServiceAI() {
  return (
    <>
<Header/>
<main>
   <ServiceAIBanner/>
<ServiceAIInfo/>
</main>
<Footer/>
</>
  );
}

export default ServiceAI;
