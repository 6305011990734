import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import ServiceCloudBanner from "./service-cloud-banner";
import ServiceCloudInfo from "./service-cloud-info";
function ServiceCloud() {
  return (
    <>
<Header/>
<main>
   <ServiceCloudBanner/>
<ServiceCloudInfo/>
</main>
<Footer/>
</>
  );
}

export default ServiceCloud;
