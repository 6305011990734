import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import ServiceBanner from "./service-banner";
import ServiceItInfo from "./service-it-info";
function ServiceItSupport() {
  return (
    <>
<Header/>
<main>
   <ServiceBanner/>
<ServiceItInfo/>
</main>
<Footer/>
</>
  );
}

export default ServiceItSupport;
