import React from "react";
import Icon03 from './images/dashboard-monitor.svg'
function ServiceAnalyticsInfo() {
  return (
    <section className="image-description-section section">
        
    <div className="image-description-left">
        <img src={Icon03} alt="Description Image"  className="image_description"/>
    </div>
    <div className="image-description-right description">
        {/* <div className="title color-bright-2">Data Analytics & Business Intelligence</div> */}
        <h2>Transform Data into Actionable  <span className="gradient-one"> Insights</span></h2>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Advanced Analytics:</span> Utilize AI to analyze large volumes of data, uncovering trends,
patterns, and insights that drive strategic decision-making.

        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Predictive Analytics:</span> Forecast future trends and behaviors using machine learning
        models, enabling proactive business strategies.

        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Custom Dashboards:</span> Tailored dashboards that provide real-time, visual insights into
        your business performance.
        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Data Integration:</span> Seamless integration of disparate data sources, ensuring a
        comprehensive view of your business operations.
        </p>
    </div>
</section>  
  )
}
export default ServiceAnalyticsInfo;