import React, { useState } from "react";
import { apiCallingPost } from "../service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ContactGetin() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleChange = (event) => {
    const { id, value } = event.target;
    const emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (id === "name") {
      setName(value);
    } else if (id === "email") {
      setEmail(value);
      if (!emailPattern.test(value)) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
      }
    } else if (id === "subject") {
      setSubject(value);
    } else if (id === "message") {
      setMessage(value)
    }
  }

  const sendMessage = () => {
    var characters = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let flag = true
    if (name === "") {
      // toast.error("Please enter name");
      toast.error("Please enter name", {
        position: "top-center"
      });
      document.getElementById("name").focus()
      flag = false
    }
    else if ((email === "") || (!characters.test(email))) {
      // toast.error("Please enter valid email Id");
      toast.error("Please enter valid email Id", {
        position: "top-center"
      });
      document.getElementById("email").focus()
      flag = false
    }
    else if (subject === "") {
      // toast.error("Please enter subject")
      toast.error("Please enter subject", {
        position: "top-center"
      });
      document.getElementById("subject").focus()
      flag = false
    }
    else if (message === "") {
      toast.error("Please enter message", {
        position: "top-center"
      });
      document.getElementById("message").focus()
      flag = false
    }
    else if (flag = true) {
      const postData =
      {
        "functionName": "sendMailContactUs",
        "subject": subject,
        "name": name,
        "email": email,
        "message": message
      }

      console.log(postData);
      apiCallingPost(postData).then((data) => {
        if (data.data.success === "1") {         
          toast.success("Thank You for contacting us! We will get back to your query soon.", {
            position: "top-center"
          });
        }else{
          toast.error(data.data.errorMessage, {
            position: "top-center"
          });
        }
      })
    }
    

  }
  return (
    <section className="section get-section">
      <ToastContainer className="text-center"/>
      <div className="section-desciption">
        <h2>Get in touch</h2>
        {/* <p className="color-bright-2">
            Our team of experts is here to help you streamline your operations
        </p> */}
      </div>
      <div className="contact-container">
        <div className="contact-form">
          <div className="form-group">
            <input type="text" id="name" name="name" placeholder="Enter your name"
              value={name} onChange={(e) => handleChange(e)} />
          </div>
          <div className="form-group">
            <input type="email" id="email" name="email" placeholder="Enter your email"
              value={email} onChange={(e) => handleChange(e)} />
            {emailError && <div className="text-danger text-small text-danger">{emailError}</div>}
          </div>
          <div className="form-group">
            <input type="text" id="subject" name="subject" placeholder="Enter the subject"
              value={subject} onChange={(e) => handleChange(e)} />
          </div>
          <div className="form-group">
            <textarea id="message" name="message" rows="6" placeholder="Enter your message"
              value={message} onChange={(e) => handleChange(e)}></textarea>
          </div>
          <div className="form-group">
            <button type="submit" className="contact-button" onClick={() => sendMessage()}>Send Message</button>
          </div>
        </div>
        <div className="contact-details">
          {/* <h2 className="gradient-one">
                Have Any Questions?
            </h2>
            <p>
                Lorem ipsum is a dummy text used to replace text in some areas just for the purpose of an example. It can be used in publishing and graphic design. The lorem ipsum text is usually a section of a Latin text by Cicero with words altered, added and removed to make it nonsensical.
            </p> */}

          <h2 className="gradient-one">Our Office</h2>
          <div className="office">
            <p className="detail-item"><i className="la la-map-marker-alt gradient-one"></i> <strong>Address: </strong> <span className="pl-2"> 6th floor, Technopark, Trivandrum</span></p>
            <p className="detail-item"><i className="la la-phone-alt gradient-one"></i> <strong>Phone: </strong> <span className="pl-2">+91 81 11 990 340</span></p>
            <p className="detail-item"><i className="la la-envelope gradient-one"></i> <strong>Email: </strong> <span className="pl-2">  info@htinfo.ai</span></p>
            <p className="detail-item"><i className="la la-clock gradient-one"></i> <strong>Hours: </strong> <span className="pl-2">  Monday - Friday, 9am - 6pm</span></p>
          </div>
          <div className="map">

          </div>
        </div>
      </div>

    </section>
  )
}
export default ContactGetin;