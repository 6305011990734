import React from "react";
import Img01 from './images/client01.webp'
import Img02 from './images/client01.webp'
import Img03 from './images/client02.webp'
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function HomeClients() {
  const options = {
    items: 2,
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    margin: 30,
    responsive: {
      0: {
        items: 1 // 0 to 576px screen size
      },
      992: {
        items: 2
      }
    }
};
  return (
<section className="testimonial-section">
    <div className="section-desciption">
        <h2>What Our Clients Are Saying</h2>
        <p className="color-bright-2">
            Discover how our custom AI solutions have transformed businesses and exceeded client
             expectations through their own words.
        </p>
    </div>
    <div className="testimonial-container">
    <OwlCarousel options={options} className="owl-theme owl-carousel" margin={10}>
                <div  className="item testimonial-item">
                    <img src={Img01} alt="Profile Image" className="profile-img"/>
            <div className="icon"><i className="fas fa-quote-left"></i></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <div className="name">John Doe</div>
            <div className="position">CEO, Company</div>
                </div>
                <div  className="item testimonial-item">
                <img src={Img02} alt="Profile Image" className="profile-img"/>
            <div className="icon"><i className="fas fa-quote-left"></i></div>
            <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <div className="name">Jane Smith</div>
            <div className="position">CTO, Company</div>
                </div>
                <div  className="item testimonial-item">
                <img src={Img03} alt="Profile Image" className="profile-img"/>
            <div className="icon"><i className="fas fa-quote-left"></i></div>
            <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
            <div className="name">Emily Johnson</div>
            <div className="position">Manager, Company</div>
                </div>
            </OwlCarousel>
    </div>
  </section>
  )
}
export default HomeClients;