import React from "react";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import BannerBg from './images/abtimg.jpg';
function AboutBanner() {
    const options = {
        items: 1,
        loop: true,
        nav: true,
        dots: true,
        autoplay: true
    };
  return (
    <section className="header-banner">
        <OwlCarousel options={options} className="owl-theme owl-carousel" id="header-slider">
                <div
                    style={{
                        backgroundImage: `url(${BannerBg})`
                    }}
                    className="item h-100vh">
                    <div className="content">
                         <h1 className="animate-bouncein mb-3">Reliable. Dependable. Responsible.</h1> 
                    <p className="animate-fadeup">HTI was founded in 1995 with the focus on helping our clients and partners keep up with
the fast-moving technology landscape: from on-premise to the cloud, from mobile to AI. In
the decades since, the only constant in the IT world is change but at HTI we pride ourselves
in always being reliable, dependable and responsible so that our customers can focus on
running their business.</p>
                    </div>
                </div>
            </OwlCarousel>

    </section>
  )
}
export default AboutBanner;