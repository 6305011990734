import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import homeNavigationSlice from "./reducers/homePageNavigationSlice";


const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["register"],
};

const reducer = combineReducers({
  homeNavigate: homeNavigationSlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
