import React from 'react';
import BannerBg from './images/bg-banner.jpg';
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function HomeBanner() {
    const options = {
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        nav: true,
        navText: [
            '<span class="owl-prev">&#10094;</span>',
            '<span class="owl-next">&#10095;</span>'
        ],
        dots: true,
        animateOut: 'fadeOut',
    };

    return (
        <section className="header-banner">
            <OwlCarousel options={options} className="owl-theme owl-carousel" id="header-slider" >
                <div
                    className="item"
                    style={{
                        backgroundImage: `url(${BannerBg})`
                    }}
                >
                    <div className="content">
                        <h1 className="animate-bouncein mb-3">AI Driven + People Powered</h1>
                        <p className="animate-fadeup">Advanced Infrastructure System Management Solutions</p>
                    </div>
                </div>
            </OwlCarousel>
        </section>
    );
}

export default HomeBanner;
