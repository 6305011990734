
import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import ContactGetin from "./contact-getin";
import ContactFind from "./contact-find";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function Contact() {
  return (
    <>
<Header/>
<main>
<ContactGetin/>
{/* <ContactFind/> */}
</main>
<Footer/>
</>
  );
}

export default Contact;
