import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages";
import About from "./pages/about";
import Contact from "./pages/contact";
import ServiceItSupport from "./pages/service-it-support";
import ServiceCybersecurity from "./pages/service-cybersecurity";
import ServiceAnalytics from "./pages/service-analytics";
import ServiceCloud from "./pages/service-cloud";
import ServiceDigital from "./pages/service-digital";
import ServiceDevOps from "./pages/service-DevOps";
import ServiceAI from "./pages/service-ai";
import SoftwareDevelopment from "./pages/software-development";
import ClientPartner from "./pages/client-and-parnters";
import TermsService from "./pages/terms-service";
import PrivacyPolicy from "./pages/privacy-policy";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>       
        <Route path="/about" element={<About />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/AI-Driven-IT-Support" element={<ServiceItSupport />}></Route>
        <Route path="/Cybersecurity-Services" element={<ServiceCybersecurity />}></Route>
        <Route path="/Data-Analytics&Business-Intelligence" element={<ServiceAnalytics />}></Route>
        <Route path="/Cloud-Management-Services" element={<ServiceCloud />}></Route>
        <Route path="/Digital-Transformation-Solutions" element={<ServiceDigital />}></Route>
        <Route path="/Managed-DevOps-Services" element={<ServiceDevOps />}></Route>
        <Route path="/AI-Powered-Customer-Support" element={<ServiceAI />}></Route>
        <Route path="/software-development" element={<SoftwareDevelopment />}></Route>
        <Route path="/client-partners" element={<ClientPartner />}></Route>
        <Route path="/app/terms-service" element={<TermsService />}></Route>
        <Route path="/app/privacy-policy" element={<PrivacyPolicy />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
