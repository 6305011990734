import React from 'react'
import Header from "./layout/header";
import Footer from "./layout/footer";

function PrivacyPolicy() {
    return (
    <>
        <Header/>
            <main>
                <div class="container">
                    <section className="section terms-wrapper">
        <h1>Privacy Policy</h1>
        <p><strong>Effective Date:</strong> Oct. 1, 2024</p>
        <p>HT Info ("we", "us", "our") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our mobile application (the "App"). By using the App, you agree to the collection and use of information in accordance with this policy.</p>

        <h2>1. Information We Collect</h2>
        <p>Facilities App does not collect, share, or sell any personally identifiable information (PII). However, the App may collect certain non-personal data that cannot be used to identify you, including:</p>
        <ul>
            <li><strong>Usage Data:</strong> We may collect anonymous information regarding how you use the App, such as interaction logs and crash reports, to improve functionality and user experience.</li>
            <li><strong>Device Data:</strong> We may collect information about the device you use to access the App, such as the device model, operating system, and app version.</li>
        </ul>

        <h2>2. Use of Collected Information</h2>
        <p>We use the collected non-personal information to:</p>
        <ul>
            <li>Improve app performance, features, and user experience.</li>
            <li>Diagnose issues, maintain, and enhance security.</li>
        </ul>
        <p>We do not use or process any personal information for advertising or marketing purposes.</p>

        <h2>3. No Sharing or Selling of Personal Data</h2>
        <p>HT Info does not collect, share, or sell your personal data to third parties. Your data remains strictly within the app environment and is used solely to enhance your experience and maintain app functionality.</p>

        <h2>4. Third-Party Services</h2>
        <p>HT Info may utilize third-party services (such as analytics tools) that comply with industry-standard privacy protections. These services may collect non-personal data as outlined in their own privacy policies. We ensure that any third-party services used are compliant with both iOS and Android privacy regulations.</p>

        <h2>5. Data Security</h2>
        <p>We are committed to protecting your data. HT Info uses industry-standard security measures to safeguard any data collected. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

        <h2>6. Children’s Privacy</h2>
        <p>HT Info does not knowingly collect any personal information from children under the age of 13. If you believe we have inadvertently collected such data, please contact us immediately so we can delete the information from our systems.</p>

        <h2>7. Compliance with iOS and Android Guidelines</h2>
        <p>HT Info adheres to the privacy policies set forth by both Apple’s App Store and Google Play Store. We are committed to ensuring full compliance with their respective privacy frameworks, including the General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other relevant regulations.</p>

        <h2>8. Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. Any changes will be posted in this section of the App. It is recommended that you review this policy periodically for any updates.</p>

        <h2>9. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
        <p>Email:  <a href="mailto:info@xilligence.com">info@xilligence.com</a></p>
        <p>Address: <br />
           611 Nila Building, <br />
           Technopark Campus, <br />
           Thiruvananthapuram, Kerala - 695 581 <br />
           India
        </p>
                </section>
                </div>
            </main>
        <Footer/>
    </>

    )
}

export default PrivacyPolicy;