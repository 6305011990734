import React, { useEffect, useRef } from "react";
import { setSectionId } from "./redux/reducers/homePageNavigationSlice";
import { useDispatch, useSelector } from "react-redux";
import Icon07 from './images/customer-service.svg'
import Icon04 from './images/cloud-gear-automation.svg'
import Icon03 from './images/dashboard-monitor.svg'
import Icon02 from './images/padlock-check.svg'
import Icon01 from './images/user-robot.svg'
import Icon06 from './images/database.svg'
import Icon05 from './images/laptop-mobile.svg'
import Icon08 from './images/display-code.svg'
import Icon09 from './images/users-svgrepo-com.svg'
import $ from 'jquery'
function HomeServices() {
    const mainHomeRef = useRef(null);
    const { sectionId } = useSelector((state) => state.homeNavigate);

    useEffect(() => {
        setTimeout(() => {
            if (sectionId !== "default") {
                $("html, body").animate({scrollTop: $("#home_service").offset().top }, 500);
            }
        }, 200);
    }, [sectionId]);
    const dispatch = useDispatch();
    useEffect(() => {
        setTimeout(() => {
            let section = "";
            if (sectionId !== "default") {
                const sectionElement = mainHomeRef.current.querySelector(
                    `${sectionId}`
                );
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
                }
                dispatch(setSectionId("default"));
            } else {
                if (sectionId) {
                    const sectionElement = mainHomeRef.current.querySelector(
                        `${sectionId}`
                    );
                    if (sectionElement) {
                        sectionElement.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                        });
                    }
                }
            }
        }, 200);
    }, [sectionId]);
    return (

        <section className="services-section section" id="home_service" ref={mainHomeRef} >
            <div className="services-left description" >
                <div className="title color-bright-2">OUR SERVICES</div>
                <h2>Modern Business is a high tech competition. HTI<span className="gradient-one">  helps you win.</span></h2>
                <p className="color-bright-1">
                    At HTI, we harness the power of advanced AI tools, combined with dedicated IT professionals,
                    to deliver cutting-edge solutions that propel your business forward. Our comprehensive suite of
                    IT solutions and managed services are designed to optimize your operations, enhance security,
                    and drive innovation. Contact us today and learn how we can help.
                </p>
            </div>
            <div className="services-right">
                <div className="service-card">
                    <a href="/AI-Driven-IT-Support">
                        {/* <i className="la la-infinity bg-1"></i> */}
                        <div className="bg-1 serivec-img-div">
                            <img src={Icon01} className="" width="72px" height="72px" />
                        </div>
                        <h3>AI-Driven IT Support</h3>
                    </a>
                </div>
                <div className="service-card">
                    <a href="/Cybersecurity-Services">
                        {/* <i className="la la-map-marker bg-2"></i> */}
                        <div className="bg-2 serivec-img-div">
                            <img src={Icon02} className="" width="72px" height="72px" />
                        </div>
                        <h3>Cybersecurity Services</h3>
                    </a>
                </div>
                <div className="service-card">
                    <a href="/Data-Analytics&Business-Intelligence">
                        {/* <i className="la la-cogs bg-3"></i> */}
                        <div className="bg-3 serivec-img-div">
                            <img src={Icon03} className="" width="72px" height="72px" />
                        </div>
                        <h3>Data Analytics & Business Intelligence</h3>
                    </a>
                </div>
                <div className="service-card">
                    <a href="/Cloud-Management-Services">
                        {/* <i className="la la-dharmachakra bg-4"></i> */}
                        <div className="bg-4 serivec-img-div">
                            <img src={Icon04} className="" width="72px" height="72px" />
                        </div>
                        <h3>Cloud Management Services</h3>
                    </a>
                </div>
                <div className="service-card">
                    <a href="/Digital-Transformation-Solutions">
                        {/* <i className="la la-share-alt bg-5"></i> */}
                        <div className="bg-5 serivec-img-div">
                            <img src={Icon05} className="" width="72px" height="72px" />
                        </div>
                        <h3>Digital Transformation Solutions</h3>
                    </a>
                </div>
                <div className="service-card">
                    <a href="/Managed-DevOps-Services">
                        {/* <i className="la la-award bg-6"></i> */}
                        <div className="bg-6 serivec-img-div">
                            <img src={Icon06} className="" width="72px" height="72px" />
                        </div>
                        <h3>Managed DevOps Services</h3>
                    </a>
                </div>
                <div className="service-card">
                    <a href="/AI-Powered-Customer-Support">
                        {/* <i className="la la-award bg-1"></i> */}
                        <div className="bg-1 serivec-img-div">
                            <img src={Icon07} className="" width="72px" height="72px" />
                        </div>
                        <h3>AI-Powered Customer Support</h3>
                    </a>
                </div>
                <div className="service-card">
                    <a href="/software-development">
                        {/* <i className="la la-infinity bg-1"></i> */}
                        <div className="bg-7 serivec-img-div">
                            <img src={Icon08} className="" width="72px" height="72px" />
                        </div>
                        <h3>Software Developement</h3>
                    </a>
                </div>
            </div>
        </section>
    )
}
export default HomeServices;