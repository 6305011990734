import React from "react";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import BannerBg from './images/software-development.jpg';
function ServiceAIBanner() {
    const options = {
        items: 1,
        loop: true,
        nav: true,
        dots: false,
        autoplay: true
    };
  return (
    <section className="header-banner">
        <OwlCarousel options={options} className="owl-theme owl-carousel" id="header-slider">
        <div
                    className="item"
                    style={{
                        backgroundImage: `url(${BannerBg})`
                    }}
                    >
                    <div className="content">
                    <h1 className="animate-bouncein mb-3">Software Developement</h1> 
                    <p className="animate-fadeup">Develop Web, Mobile and AI enabled applications on-time and on budget</p>
                    </div>
                </div>
            </OwlCarousel>
    </section>
  )
}
export default ServiceAIBanner;