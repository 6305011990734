import React from "react";
import Logo from '../images/hti-light.png'
import Xilli from '../images/xi-footer-logo.webp'
import { animateScroll as scroll } from 'react-scroll';
import { useNavigate, useLocation } from 'react-router-dom';
import { useScrollContext } from "../context/ScrollContext";
import { Link } from "react-router-dom";
import { setSectionId } from "../redux/reducers/homePageNavigationSlice";
import { useDispatch } from "react-redux";
function Footer() {
    const year = new Date().getFullYear();
    const dispatch = useDispatch()
    const location = useLocation()

    const { setSelectedSection } = useScrollContext();
    const handleLinkClick = (sectionId) => {
        if (location.pathname === "/") {
            dispatch(setSectionId(sectionId));
        } else {
            dispatch(setSectionId(sectionId));
            window.location.href = "/";
        }
    };
    return (
        <footer id="footer">
            <div className="footer-container">
                <div className="footer-logo">
                    <img src={Logo} alt="Logo" className="logo" />
                    <div className="social footer-social">
                        <div className="effect varrius">
                            <div className="buttons">
                                <a href="https://www.facebook.com/" className="fb curserhover" title="Join us on Facebook"><i className="la la-facebook icon" aria-hidden="true"></i></a>
                                <a href="https://twitter.com/" className="tw curserhover" title="Join us on Twitter"><i className="la la-twitter icon" aria-hidden="true"></i></a>
                                <a href="https://www.youtube.com/channel/" className="youtube curserhover" title="Join us on youtube"><i className="la la-play icon" aria-hidden="true"></i></a>
                                <a href="https://www.instagram.com/" className="insta curserhover" title="Join us on Instagram"><i className="la la-instagram icon" aria-hidden="true"></i></a>
                                <a href="https://www.linkedin.com/company/" className="linked curserhover" title="Join us on Instagram"><i className="la la-linkedin icon" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-links">
                    <div className="links-column">
                        <a href="/about">About Us</a>
                        <Link onClick={(e) => handleLinkClick("#home_service")} to="/" className="main_header_href">
                            Our Services
                        </Link>
                        <a href="/contact">Contact</a>
                    </div>
                    {/* <div className="links-column">
                <a href="#">Careers</a>
                <a href="#">Partner with Us</a>
                <a href="#">Press and Media</a>
                <a href="#">Resource Center</a>
            </div> */}
                </div>
                <div className="footer-subscribe footer-links">
                    <div className="links-column">
                <a href="javascript:void(0)" className="main_header_href">Privacy Policy</a>
                <a href="javascript:void(0)" className="main_header_href">Terms of Service</a>
                <a href="javascript:void(0)" className="main_header_href">FAQs</a>
                {/* <a href="javascript:void(0)" className="main_header_href">AI-Ethics and Guidelines</a> */}
                </div>
                </div>
            </div>

            <div className="footer-bottom">
                <p className="footer-xilligence">&copy;<span className="pl-2">
                HTI is part of the Xilligence Group  </span> 
                    {/* {year}  */}
                    <a href="https://www.xilligence.com/" className="xilli-logo-footer m-0 pl-2"> 
                    <img src={Xilli} alt="logo" className="img-fluid logo-footer" width="110" height="30"/></a> 
                   </p>
                
            </div>

        </footer>
    )
}
export default Footer;