import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import ServiceDigitalBanner from "./service-digital-banner";
import ServiceDigitalInfo from "./service-digital-info";
function ServiceDigital() {
  return (
    <>
<Header/>
<main>
   <ServiceDigitalBanner/>
<ServiceDigitalInfo/>
</main>
<Footer/>
</>
  );
}

export default ServiceDigital;
