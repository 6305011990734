import { createSlice } from "@reduxjs/toolkit";

export const homeNavigationSlice = createSlice({
  name: "counter",
  initialState: {
    sectionId: "default",
  },

  reducers: {
    setSectionId: (state, action) => {
      state.sectionId = action.payload;
    },
  },
});

export const { setSectionId } = homeNavigationSlice.actions;

export default homeNavigationSlice.reducer;
