import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import ServiceAnalyticsBanner from "./service-analytics-banner";
import ServiceAnalyticsInfo from "./service-analytics-info";
function ServiceAnalytics() {
  return (
    <>
<Header/>
<main>
   <ServiceAnalyticsBanner/>
<ServiceAnalyticsInfo/>
</main>
<Footer/>
</>
  );
}

export default ServiceAnalytics;
