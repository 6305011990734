import React from "react";
import Icon07 from './images/display-code.svg'
function ServiceAIInfo() {
  return (
    <section className="image-description-section section">
        
    <div className="image-description-left">
        <img src={Icon07} alt="Description Image" className="image_description"/>
    </div>
    <div className="image-description-right description">
        {/* <div className="title color-bright-2">AI-Powered Customer Support</div> */}
        <h2>Develop Web, Mobile and AI enabled applications <span className="gradient-one"> on-time and on budget</span></h2>
        <p className="color-bright-1  para_list">
        Web, Mobile and Desktop applications using modern languages and frameworks
        </p>
        <p className="color-bright-1  para_list">
        Generative AI enabled applications using OpenAi (ChatGPT), Meta Llama and more.

        </p>
        <p className="color-bright-1  para_list">
        End-to-End Software Services: UI/UX design, development, cloud deployment,
        support and maintenance.

        </p>
        <p className="color-bright-1  para_list">
        Build to order dedicated software teams managed by HTI on your behalf
        </p>
    </div>
</section>  
  )
}
export default ServiceAIInfo;