import React from "react";
import client1 from './images/leela.png'
import client2 from './images/ibm.png'
import client3 from './images/cipla.png'
import client4 from './images/kyndryl.png'
import client5 from './images/bitsom.png'
import client6 from './images/vi.png'
import client7 from './images/bridgestone.png'
import client8 from './images/flipkart.png'
import client9 from './images/lenovo.png'
import client10 from './images/adani.png'
import client11 from './images/aditya.png'
import client12 from './images/cma-cgm.png'
import client13 from './images/au.png'
import client14 from './images/ujjivan.png'
import client15 from './images/pocl.png'
import client16 from './images/aizant.png'
import client17 from './images/gaar.png'
import client18 from './images/abb.png'
import client19 from './images/airtel.png'
import client20 from './images/bharti.png'
import client21 from './images/canara.png'
import client22 from './images/dow.png'
import client23 from './images/ge.png'
import client24 from './images/hdfc.png'
import client25 from './images/hero.png'
import client26 from './images/hundai.png'
import client27 from './images/income.png'
import client28 from './images/maruthi.png'
import client29 from './images/mondetez.png'
import client30 from './images/royal-bank.png'
import client31 from './images/standard.png'
import client32 from './images/state-street.png'
import client33 from './images/tcs.png'
import client34 from './images/wipro.png'
import client35 from './images/dell.png'
function ServiceAIInfo() {
  return (
    <section className="image-description-section section">

    <div className="client-logos">
      <div className="logos">
        <img src={client1} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client2} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client3} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client4} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client5} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client6} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client7} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client8} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client9} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client10} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client11} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client12} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client13} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client14} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client15} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client16} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client17} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client19} alt="client1" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client18} alt="client18" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client20} alt="client20" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client21} alt="client21" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client22} alt="client22" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client23} alt="client23" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client24} alt="client24" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client25} alt="client25" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client26} alt="client26" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client27} alt="client27" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client28} alt="client28" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client29} alt="client29" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client30} alt="client30" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client31} alt="client31" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client32} alt="client32" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client33} alt="client33" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client34} alt="client34" width="250" height="120" className="img-fluid"/>
      </div>
      <div className="logos">
        <img src={client35} alt="client35" width="250" height="120" className="img-fluid"/>
      </div>
      
    </div>
    </section>
  )
}
export default ServiceAIInfo;