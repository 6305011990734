import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import AboutBanner from "./about-banner";
import AboutInfo from "./about-info";
import AboutAISolution from "./about-ai-solution";
import AboutMeet from "./about-meet";
import ContactGetin from "./contact-getin";
function About() {
  return (
    <>
<Header/>
<main className="about-main">
<AboutBanner/>
  {/* <AboutInfo/> */}
  {/* <AboutAISolution/> */}
   <AboutMeet/>    
   <ContactGetin/>
</main>
<Footer/>
</>
  );
}

export default About;
