import React from "react";
import abotInfo from './images/special.avif'
function AboutAISolution() {
  return (
    <section className="section">
        <div className="section-desciption">
            <h2>Custom AI Solutions</h2>
            <p className="color-bright-2">
                Our comprehensive approach ensures meticulous planning and execution at every stage, from initial research to final deployment. By blending advanced technology with strategic insights, we deliver innovative and reliable AI solutions tailored to our clients' needs.
            </p>
        </div>
        <div className="Process_processContainer page-container">
            
            <div className="Process_processWrapContainer" id="processContainer">
                <div id="wrapId">
                    <div className="pin-spacer">
                        <div className="Process_scrollElement" id="scrollElement"></div>
                    </div>
                    <div>
                        <div>
                            <div
                                className="Process_processWrap isScrollView"
                                data-scroll="true"
                                data-scroll-repeat="true"
                                data-scroll-offset="50%, 50%"
                                data-scroll-position="top"
                                data-scroll-call="0"
                            >
                                <h5>01</h5>
                                <h3>Strategy</h3>
                                <div className="">
                                    <p className="">
                                        We integrate key elements to deliver exceptional custom AI solutions for our clients.
                                    </p>
                                </div>
                                <div className="Process_processCotentList">
                                    <div className="Process_boxContent">
                                        <div className="">
                                            <ul>
                                                <li>
                                                    <p className="list">Market Analysis </p>
                                                </li>
                                                <li>
                                                    <p className="list">Data Collection & Project Scoping</p>
                                                </li>
                                                <li>
                                                    <p className="list">Milestone Planning & Risk Assessment</p>
                                                </li>
                                               
                                            </ul>
                                            <p className=""></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="Process_processWrap isScrollView"
                                data-scroll="true"
                                data-scroll-repeat="true"
                                data-scroll-offset="50%, 50%"
                                data-scroll-position="top"
                                data-scroll-call="1"
                            >
                                <h5>02</h5>
                                <h3>Development</h3>
                                <div className="">
                                    <p className="">
                                        Our AI expertise allows us to address problems from various angles, ensuring robust solutions.
                                    </p>
                                </div>
                                <div className="Process_processCotentList">
                                    <div className="Process_boxContent">
                                        <div className="">
                                            <ul>
                                                <li>
                                                    <p className="list">Custom Model Creation</p>
                                                </li>
                                                <li>
                                                    <p className="list">Integration</p>
                                                </li>
                                                <li>
                                                    <p className="list">Quality Assurance</p>
                                                </li>
                                            </ul>
                                            <p className=""></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="Process_processWrap isScrollView"
                                data-scroll="true"
                                data-scroll-repeat="true"
                                data-scroll-offset="50%, 50%"
                                data-scroll-position="top"
                                data-scroll-call="2"
                            >
                                <h5>03</h5>
                                <h3>Deployment</h3>
                                <div className="">
                                    <p className="">
                                        We ensure flawless implementation, making AI solutions operational, secure, and effective.
                                    </p>
                                </div>
                                <div className="Process_processCotentList">
                                    <div className="Process_boxContent">
                                        <div className="">
                                            <ul>
                                                <li>
                                                    <p className="">Implementation</p>
                                                </li>
                                                <li>
                                                    <p className="">Monitoring and Maintenance</p>
                                                </li>
                                                <li>
                                                    <p className="">Security</p>
                                                </li>
                                                <li>
                                                    <p className="">Mind Mapping</p>
                                                </li>
                                                <li>
                                                    <p className="">Personas</p>
                                                </li>
                                            </ul>
                                            <p className=""></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
  )
}
export default AboutAISolution;