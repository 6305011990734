import React from "react";
import abotInfo from './images/special.avif'
import Icon01 from './images/user-robot.svg'
function ServiceItInfo() {
  return (
    <section className="image-description-section section">
        
    <div className="image-description-left">
        <img src={Icon01} alt="Description Image" className="image_description"/>
    </div>
    <div className="image-description-right description">
        {/* <div className="title color-bright-2">AI-Driven IT Support</div> */}
        <h2>Empower Your Business with Intelligent <span className="gradient-one"> IT Support</span></h2>
        <p className="color-bright-1 para_list">
        <span className="text-bold">24/7 Proactive Monitoring:</span> Our AI-powered systems monitor your IT infrastructure
        around the clock, identifying and resolving issues before they impact your business.
        </p>
        <p className="color-bright-1 para_list">
        <span className="text-bold">Predictive Maintenance:</span> Leveraging machine learning algorithms, we predict potential
hardware failures and schedule maintenance proactively, minimizing downtime.

        </p>
        <p className="color-bright-1 para_list">
        <span className="text-bold">Automated Troubleshooting:</span> Our AI tools quickly diagnose and fix common IT problems,
reducing resolution times and increasing efficiency.

        </p>
        <p className="color-bright-1 para_list">
        <span className="text-bold">Virtual Assistants:</span> AI-driven virtual assistants for instant support, enhancing employee
        productivity and satisfaction.
        </p>
    </div>
</section>  
  )
}
export default ServiceItInfo;