import React from "react";
// import OwlCarousel from 'react-owl-carousel2';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import Meet01 from './images/santhosh.jpg'
import Meet02 from './images/prakash.jpg'
import Meet03 from './images/mini.jpg'
import Meet05 from './images/Madhyamvetu Shivadas.jpg'
import Meet04 from './images/vikram.jpg'
function AboutMeet() {

  return (
    <section className="section about-meet-session">
      <div className="section-desciption">
        <h2 className="gradient-one">Our Leadership teams</h2>
        <p className="color-bright-2">
          Our comprehensive approach ensures meticulous.
        </p>
      </div>
      <div className="team-section">
        <div className="team-carousel member-data">
          <div className="team-member-data">
            <div className="team-member">
              <div className="member-image">
                <img src={Meet01} alt="Team Member 1" />
                <div className="social-icons">
                  <a href="javascript:void(0)" className="la la-linkedin"></a>
                  <a href="javascript:void(0)" className="la la-facebook"></a>
                  <a href="javascript:void(0)" className="la la-instagram"></a>
                </div>
              </div>
              <div className="member-info">
                <h3>Santosh Menon</h3>
                <p>CEO</p>
              </div>

            </div>
            <div className="member-description">Santosh is a business leader with over 25 years of extensive experience in international business, across technology, energy, finance, and sports. He has the ability to navigate complex business landscapes, driving growth and innovation. A people-oriented leader, he believes in the power of collaboration and has a proven track record of building high-performing teams. He brings a strong commitment to integrating innovative technologies to enhance operational efficiency and value creation for customers. Santosh is a Graduate in Economics and Marketing from the UK. </div>
          </div>
          <div className="team-member-data">
            <div className="team-member">
              <div className="member-image">
                <img src={Meet02} alt="Team Member 2" />
                <div className="social-icons">
                  <a href="javascript:void(0)" className="la la-linkedin"></a>
                  <a href="javascript:void(0)" className="la la-facebook"></a>
                  <a href="javascript:void(0)" className="la la-instagram"></a>
                </div>
              </div>
              <div className="member-info">
                <h3>Prakash Sathyaplan</h3>
                <p>CTO</p>
              </div>
            </div>
            <div className="member-description">Prakash is the CTO of HTI. He ensures that the HTI technology teams always deliver for
              our clients. He spent his career working in India and the US for leading tech companies in
              senior technology and managerial roles in companies like Stubhub(eBay), IBM (Cognos)
              and SAS Institute. He has over 20 plus years experience in software development, design,
              Data Science and Business Intelligence. Prakash holds a Master‘s degree in Computer science</div>
          </div>
          <div className="team-member-data">
            <div className="team-member">
              <div className="member-image">
                <img src={Meet03} alt="Team Member 2" />
                <div className="social-icons">
                  <div className="social-icons">
                    <a href="javascript:void(0)" className="la la-linkedin"></a>
                    <a href="javascript:void(0)" className="la la-facebook"></a>
                    <a href="javascript:void(0)" className="la la-instagram"></a>
                  </div>
                </div>
              </div>
              <div className="member-info">
                <h3>Mini Balarman</h3>
                <p>COO</p>
              </div>
            </div>
            <div className="member-description">Mini is the COO of HTI. She is responsible for key operational aspects of HTI and ensures
              that the overall HTI organization works optimally across all facets of the company. Mini
              returned to India after 14 years in the US, having worked at Blue Shield of California and
              Sun Microsystems (now Oracle) in various technical roles, from software development to
              Business Intelligence. She combines a unique blend of client-facing experience and strong technical knowledge. Mini holds a Master‘s degree in Computer Applications.</div>
          </div>
          <div className="team-member-data">
            <div className="team-member">
              <div className="member-image">
                <img src={Meet05} alt="Team Member 2" />
                <div className="social-icons">
                  <div className="social-icons">
                    <a href="javascript:void(0)" className="la la-linkedin"></a>
                    <a href="javascript:void(0)" className="la la-facebook"></a>
                    <a href="javascript:void(0)" className="la la-instagram"></a>
                  </div>
                </div>
              </div>
              <div className="member-info">
                <h3>Mr. Madhyamvetu Shivadas
</h3>
                <p>
                Company Founder & Director</p>
              </div>
            </div>
            <div className="member-description">Mr. Shivadas founded HTI (Hi-Tech Informatics) in 1995. Earlier in his career he provided IT support services for ICL, a British computer giant. From there he took his experience to found HTI and soon after provided IT infra services in the telecom, manufacturing, and banking sectors in India. He grew HTI to a pan-Indian operation and it soon became a leading player in the IT Infrastructure & Support sector with major companies as clients and partners.. Mr. Shivadas made HTI a success with a relentless focus on providing the highest quality of customer service. He is also well known in the industry for developing the careers of many successful young IT professionals in India and beyond. He continues to provide guidance and inspiration to HTI.</div>
          </div>
          <div className="team-member-data">
            <div className="team-member">
              <div className="member-image">
              <img src={Meet04} alt="Team Member 1" />
                <div className="social-icons">
                  <a href="javascript:void(0)" className="la la-linkedin"></a>
                  <a href="javascript:void(0)" className="la la-facebook"></a>
                  <a href="javascript:void(0)" className="la la-instagram"></a>
                </div>
              </div>
              <div className="member-info">
                <h3>Vikram Nair</h3>
                <p>Executive Director</p>
              </div>
            </div>
            <div className="member-description">Mr. Vikram the Executive Director of HTI and has over 20 years of experience working with
              technology companies in leadership, strategy and product design roles in North America
              and India. In addition, as an entrepreneur, he has helped found multiple start-ups. He
              believes in the ability of technology to empower people, bring them closer together and
              change the world for the better.</div>
          </div>
        </div>
      </div>

    </section>
  )
}
export default AboutMeet;