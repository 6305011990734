import React from "react";
import Icon05 from './images/laptop-mobile.svg'
function ServiceDigitalInfo() {
  return (
    <section className="image-description-section section">
        
    <div className="image-description-left">
        <img src={Icon05} alt="Description Image" className="image_description"/>
    </div>
    <div className="image-description-right description">
        {/* <div className="title color-bright-2">Digital Transformation Solutions</div> */}
        <h2>Accelerate Your Digital Transformation  <span className="gradient-one">Journey</span></h2>
        <p className="color-bright-1  para_list">
        <span className="text-bold">AI Strategy Development:</span> Craft a comprehensive AI strategy that aligns with your
business goals and maximizes ROI.
        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Process Automation:</span> Identify and automate repetitive tasks using AI, improving
        efficiency and freeing up human resources for higher-value activities.

        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Application Development:</span> Develop Cloud, Mobile and Desktop applications with modern
technology stacks and enhanced using AI powered software develiopment

        </p>
    </div>
</section>  
  )
}
export default ServiceDigitalInfo;