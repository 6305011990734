import React from "react";
import Icon04 from './images/cloud-gear-automation.svg'
function ServiceCloudInfo() {
  return (
    <section className="image-description-section section">
        
    <div className="image-description-left">
        <img src={Icon04} alt="Description Image" className="image_description"/>
    </div>
    <div className="image-description-right description">
        {/* <div className="title color-bright-2">Cloud Management Services</div> */}
        <h2>Optimize Your Cloud Infrastructure  <span className="gradient-one">with AI</span></h2>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Intelligent Resource Allocation:</span> AI algorithms dynamically allocate cloud resources
based on demand, ensuring optimal performance and cost-efficiency.
        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Automated Scaling:</span> Automatically scale your cloud infrastructure up or down in response
        to traffic patterns and workload demands.

        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Security Management:</span> Continuous monitoring and AI-driven threat detection to
safeguard your cloud environment.

        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Cost Optimization:</span> Analyze usage patterns and optimize your cloud spend, eliminating
waste and reducing costs.
        </p>
    </div>
</section>  
  )
}
export default ServiceCloudInfo;