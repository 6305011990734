import React from "react";
import abotInfo from './images/special.avif'
import Icon02 from './images/padlock-check.svg'
function ServiceCybersecurityItInfo() {
  return (
    <section className="image-description-section section">
        
    <div className="image-description-left">
        <img src={Icon02} alt="Description Image" className="image_description"/>
    </div>
    <div className="image-description-right description">
        {/* <div className="title color-bright-2">Cybersecurity Services</div> */}
        <h2>Protect Your Data with Advanced AI  <span className="gradient-one"> Security Solutions</span></h2>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Real-Time Threat Detection:</span> Our AI algorithms continuously analyze network traffic to
detect and neutralize threats in real time. Secuity Information Event Management (SIEM)

        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Behavioral Analysis:</span> Using AI to monitor user behavior, we can identify and mitigate
        insider threats and suspicious activities.

        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Automated Incident Response:</span> AI-driven response mechanisms ensure swift action
        against security breaches, minimizing damage and recovery time.

        </p>
        <p className="color-bright-1  para_list">
        <span className="text-bold">Vulnerability Management:</span> Continuous scanning and assessment of your systems to
        identify and patch vulnerabilities before they are exploited.
        </p>
        <p className="color-bright-1  para_list">
        Managed Secuity Services Provider (MSSP) for Security Operations Centers (SOC)
        services.
        </p>
    </div>
</section>  
  )
}
export default ServiceCybersecurityItInfo;