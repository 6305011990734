import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import ServiceCybersecurityBanner from "./service-cybersecurity-banner";
import ServiceCybersecurityItInfo from "./service-cybersecurity-info";
function ServiceCybersecurity() {
  return (
    <>
<Header/>
<main>
   <ServiceCybersecurityBanner/>
<ServiceCybersecurityItInfo/>
</main>
<Footer/>
</>
  );
}

export default ServiceCybersecurity;
