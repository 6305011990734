import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import ServiceDevOpsBanner from "./service-DevOps-banner";
import ServiceDevOpsInfo from "./service-DevOps-info";
function ServiceDevOps() {
  return (
    <>
<Header/>
<main>
   <ServiceDevOpsBanner/>
<ServiceDevOpsInfo/>
</main>
<Footer/>
</>
  );
}

export default ServiceDevOps;
