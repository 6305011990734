// ScrollContext.js
import { createContext, useContext, useEffect, useState } from 'react';

const ScrollContext = createContext();

export const useScrollContext = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }) => {
  console.log("children..children", children)
  const [selectedSection, setSelectedSection] = useState('');
  useEffect(() => {
    console.log("selectedSection", selectedSection);
  }, [selectedSection])
  return (
    <ScrollContext.Provider value={{ selectedSection, setSelectedSection }}>
      {children}
    </ScrollContext.Provider>
  );
};
