import React, { useEffect } from "react";
import Header from "./layout/header";
import HomeBanner from "./home-banner";
import HomeServices from "./home-services";
import HomeScale from "./home-scale";
import HomeSpecialities from "./home-specialities";
import HomeClients from "./home-clients";
import Footer from "./layout/footer";
function Home() {

  return (
    <>
<Header/>
<main>
   <HomeBanner/>
    <HomeServices/>
    {/* <HomeScale/> */}
    {/* <HomeSpecialities/> */}
    {/* <HomeClients/> */}
</main>
<Footer/>
</>
  );
}

export default Home;
